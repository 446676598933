import React, { useEffect, useState, useContext } from "react";
import TopNav from "./../navigation/TopNav";
import TopHeader from "./../navigation/TopHeader";
import { FormattedMessage } from "react-intl";
import BottomNavigation from "./../navigation/BottomNavigation";
import Loader from "./../utils/Loader";
import { AppContext } from "./../App";
import endpoint from "../../api";
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Video() {
  const { selectedLanguage, selectedSite } = useContext(AppContext);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    endpoint
      .get(`/${selectedSite}/${selectedLanguage.value}/guide/`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  }, [selectedLanguage.value]);

  return (
    <>
    <div className="screen-container">
      <TopNav>
        <h1>
          <FormattedMessage id="topnav.video.h1" />
        </h1>
      </TopNav>
      <TopHeader>
        <h2>
          <FormattedMessage id="topheader.video.form.h2" />
        </h2>
        <p>
          <FormattedMessage id="topheader.video.form.p" />
        </p>
      </TopHeader>
      <div className="screen-content">
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            data.map((item, index) => (
              <div className="card card--white video">
                <div className="card-content">
                  <div className="container">
                    <div className="row">
                      <div className="video-image col-xs-6 text-center">
                        <img src={item.image} />
                      </div>
                      <div className="video-details col-xs-6">
                        <h3
                          data-filter-card--title={item.title}
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <p dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-action">
                  {item.isvideo ? (
                    <a
                      href={item.url}
                      data-youtube=""
                      data-listing-card-id={item.id}
                    >
                      <FormattedMessage id={"play.video"} />
                    </a>
                  ) : (
                    <a
                      href={item.url}
                      target="_blank"
                      data-listing-card-id={item.id}
                    >
                      <FormattedMessage id={"label.download"} />
                    </a>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <BottomNavigation />
    </div>
    </>
  );
}

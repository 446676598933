import React, { useState, useEffect, useContext } from 'react';
import TopNav from './../navigation/TopNav';
import BottomNavigation from './../navigation/BottomNavigation';
import Loader from './../utils/Loader';
import Logo from './../utils/Logo';
import endpoint from '../../api';
import { AppContext } from './../App'
import { Helmet } from 'react-helmet';
//import google from 'react-ga';
//google.initialize('UA-151343945-1');
//google.pageview(window.location.pathname + window.location.search);

export default function Index({ match }) {
    const { selectedLanguage, selectedSite } = useContext(AppContext)

    const [loading, setLoading] = useState()
    const [content, setContent] = useState({ title: '', body: '' })
    const [usdol, setUsdol] = useState({ title: '', body: '' })

    useEffect(() => {
        setLoading(true)
        endpoint.get(`/${selectedSite}/${selectedLanguage.value}/page/${match.params.slug}`).then(response => {
            setLoading(false)
            setContent(response.data)
	    if(match.params.slug === "about") {
                endpoint.get(`/${selectedSite}/${selectedLanguage.value}/page/us-dol`).then(response => {
		    setLoading(false)
		    setUsdol(response.data)
		})
	    } else {
                setLoading(false)
            }
        })
    }, [match.params.slug, selectedLanguage.value])

    return (
        <div className="screen-container">
            {loading ?
                <Loader />
                    :
                <>
                    <Helmet>
                        <title>{content.title}</title>
                    </Helmet>
                    <TopNav>
                        <h1>{content.title}</h1>
                    </TopNav>
                    <div className="screen-content screen-content--white">
                        <div className="container text-container">
                            <div dangerouslySetInnerHTML={ {__html: content.body} } />
                            {match.params.slug === "about" && <Logo />}
                            {match.params.slug === "about" && <div className="us-dol-about" dangerouslySetInnerHTML={ {__html: usdol.body} } />}
                        </div>
                    </div>
                </>
            }
            <BottomNavigation />
        </div>
    );
}
